/*  eslint-disable */
export default {
  ANSWERS: {
    STEP_1: {
      LEFT: "Left",
      RIGHT: "Right",
      INDEPENDENT: "Independent",
    },
    STEP_3: {
      GOOD: "Good",
      BAD: "Bad",
    },
  },
};
